import Api, { addCoaching, params } from '../modules/Api.js';
import fr from '@/lang/fr.js';
import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';
import { getMe, GET_ME_SUCCESSFUL, GET_ME_FAILED } from './me';

export const LOGIN_SUCCESSFUL = 'auth/LOGIN_SUCCESSFUL';
export const LOGIN_FAILED = 'auth/LOGIN_FAILED';
export const RESET_SUCCESSFUL = 'auth/RESET_SUCCESSFUL';
export const RESET_FAILED = 'auth/RESET_FAILED';
export const NEW_SUCCESSFUL = 'auth/NEW_SUCCESSFUL';
export const MAINTENANCE_MODE = 'global/MAINTENANCE_MODE';
export const IS_ERROR_ALERT = 'global/IS_ERROR_ALERT';
export const RESET_ERROR_ALERT = 'global/RESET_ERROR_ALERT';
export const LOGOUT_REQUESTED = 'auth/LOGOUT_REQUESTED';
export const CAPTCHA_REQUEST_ANTIBOT = 'auth/CAPTCHA_REQUEST_ANTIBOT';
export const CAPTCHA_REQUEST_ANTIBOT_TOKEN = 'auth/CAPTCHA_REQUEST_ANTIBOT_TOKEN';

const initialState = {
  positioningState: undefined,
  isAuthenticated: false,
  maintenance: false,
  message: undefined,
  isConnected: false,
  isErrorAlert: false
};

export default function Auth(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESSFUL:
      return {
        ...state,
        isAuthenticated: true,
        message: undefined,
        positioningState: true,
        isConnected: true,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        positioningState: false,
        message: undefined,
      };
    case RESET_SUCCESSFUL:
      return {
        ...state,
        isAuthenticated: false,
        message: action.message,
      };
    case NEW_SUCCESSFUL:
      return {
        ...state,
        isAuthenticated: false,
        message: action.message,
      };
    case LOGOUT_REQUESTED:
      return {
        ...state,
        isAuthenticated: false,
        positioningState: false,
        message: action.message,
      };
    case MAINTENANCE_MODE:
      return {
        ...state,
        maintenance: true,
      };
    case IS_ERROR_ALERT:
      return {
        ...state,
        isErrorAlert: true,
      };
    case RESET_ERROR_ALERT:
      return {
        ...state,
        isErrorAlert: false,
      };
    case RESET_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        message: action.message,
      };
    case CAPTCHA_REQUEST_ANTIBOT:
      return {
        ...state,
        captcha: action.captcha,
        isLoggable: !action?.captcha?.apiCaptchaIsOk ? true : state?.isLoggable
      };
    case CAPTCHA_REQUEST_ANTIBOT_TOKEN :
      return {
        ...state,
        isLoggable : true,
        captcha: null
      };
    default:
      return state;
  }
}

export const submit = (data, section, coach) => {
  return (dispatch) => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });

    switch (section) {
      case 'login':
        Api.post('/login_check', data, { params: params })
          .then((res) => {
            coach && addCoaching(coach);
            if (res && res.status && res.status === 451) {
              dispatch({
                type: SET_ERROR,
                value: 'unauthorized'
              });
              if (res?.data?.url) {
                window.location.replace(res?.data?.url);
              }
            } else if (res && res.status && res.status !== 200) {
              dispatch({
                type: SET_ERROR,
                value: 'unauthorized'
              });
              dispatch({ type: LOGIN_FAILED });
              dispatch({ type: ISNT_LOADING });
            }else if (res && res?.status === 200) {
              localStorage.setItem('token', res.data.token);
              getMe(res.data.token)
                .then(user => {
                  dispatch({
                    type: GET_ME_SUCCESSFUL,
                    user: user
                  });
                  dispatch({ type: LOGIN_SUCCESSFUL });
                  dispatch({ type: ISNT_LOADING });
                })
                .catch(err => {
                  dispatch({
                    type: SET_ERROR,
                    value: err.response && err.response.status === 401 ? 'unauthorized' : 'error'
                  });

                  dispatch({ type: GET_ME_FAILED });
                  dispatch({ type: LOGIN_FAILED });
                  dispatch({ type: ISNT_LOADING });
                });
            }
          })
          .catch((err) => {
            dispatch({
              type: SET_ERROR,
              value:
                err?.response && err?.response?.status === 401
                  ? 'unauthorized'
                  : 'error',
            });
            dispatch({ type: ISNT_LOADING });
          });

        break;

      case 'reset':
        Api.post('/reset_password', data, { params: params }).then((res) => {
          if (res?.status !== 200 ) {
            dispatch({
              type: RESET_FAILED,
              message: fr.login.error.error,
            });
            dispatch({
              type: SET_ERROR,
              value: 'error'
            });
          } else {
            dispatch({
              type: RESET_SUCCESSFUL,
              message: fr.login.success.reset,
            });
          }
          dispatch({ type: ISNT_LOADING });
        });
        break;

      case 'new':
        Api.put('/new_password', data, { params: params })
          .then(() => {
            dispatch({
              type: NEW_SUCCESSFUL,
              message: fr.login.success.new,
            });
            dispatch({ type: ISNT_LOADING });
          })
          .catch((err) => {
            dispatch({
              type: SET_ERROR,
              value:
                err?.response && err?.response?.status === 400
                  ? 'invalidToken'
                  : 'error',
            });
            dispatch({ type: ISNT_LOADING });
          });

        break;

      default:
        break;
    }
  };
};

export const check_token = token => {
  return async dispatch => {
    dispatch({type: IS_LOADING});
    Api.get('/check_token/' + token)
      .then(res => {
        if(res.status === 500){
          dispatch({
            type: SET_ERROR,
            value: 'invalidToken'
          });
        }
      })
      .catch(err => {
        dispatch({
          type: SET_ERROR,
          value: err.response && err.response.status === 400 ? 'invalidToken' : 'error'
        });
      });
    dispatch({type: ISNT_LOADING});
  };
};

export const forced_logout = (dispatch) => {
  dispatch({ type: SET_ERROR, value: 'notLoggedIn' });
  dispatch({ type: IS_LOADING });

  localStorage.removeItem('token');
  localStorage.removeItem('coach');

  dispatch({ type: LOGOUT_REQUESTED });
  dispatch({ type: ISNT_LOADING });
};

export const maintenanceMode = (dispatch) => {
  dispatch({ type: IS_LOADING });
  dispatch({ type: MAINTENANCE_MODE });
  dispatch({ type: ISNT_LOADING });
};

export const isErrorAlert = (dispatch) => {
  dispatch({ type: IS_LOADING });
  dispatch({ type: IS_ERROR_ALERT });
  dispatch({ type: ISNT_LOADING });
};

export const resetErrorAlert = (dispatch) => {
  dispatch({ type: IS_LOADING });
  dispatch({ type: RESET_ERROR_ALERT });
  dispatch({ type: ISNT_LOADING });
};

export const logout = () => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });

  localStorage.removeItem('token');
  localStorage.removeItem('coach');

  dispatch({ type: LOGOUT_REQUESTED });
  dispatch({ type: ISNT_LOADING });
};

export const checkMaintenance = () => {
  return async dispatch => {
    dispatch({type: IS_LOADING});
    Api.get('/check_maintenance')
      .then(res => {
        if(res.status === 503 && res.data){
          dispatch({
            type: MAINTENANCE_MODE
          });
        }
      })
      .catch(err => {
        dispatch({
          type: SET_ERROR,
          value: err.response
        });
      });
    dispatch({type: ISNT_LOADING});
  };
}

export const checkCaptchaIsNeed = () => {
  return async dispatch => {
    dispatch({type: IS_LOADING});
    Api.get('/check_captcha')
      .then(res => {
        if (res.status !== 200) {
          dispatch({
            type: SET_ERROR,
            value: 'catpchaError'
          });
        } else {
          dispatch({
            type: CAPTCHA_REQUEST_ANTIBOT,
            captcha: res.data,
            apiCaptchaIsOk: res.data?.apiCaptchaIsOk
          });
        }
      })
      .catch(err => {
        dispatch({
          type: SET_ERROR,
          value: err.response
        });
      });
    dispatch({type: ISNT_LOADING});
  };
};

export const sendAntiBotToken = (token) => {
  return async dispatch => {
    dispatch({type: IS_LOADING});
    Api.post('/check_antiBotToken', {token}, {params: params} )
      .then(res => {
        if (res.status === 403) {
          dispatch({
            type: SET_ERROR,
            value: 'catpchaBlackListUser'
          });
        } else if (res.status !== 200) {
          dispatch({
            type: SET_ERROR,
            value: 'catpchaError'
          });
        } else {
          dispatch({
            type: CAPTCHA_REQUEST_ANTIBOT_TOKEN,
            value: true
          });
        }
      })
      .catch(err => {
        dispatch({
          type: SET_ERROR,
          value: err.response
        });
      });
    dispatch({type: ISNT_LOADING});
  };
};

export const initializeCaptcha = (antibotId, requestId) => {
  return async dispatch => {
    dispatch({type: IS_LOADING});
    if (window.LI_ANTIBOT && antibotId && requestId) {
      // eslint-disable-next-line no-undef
      LI_ANTIBOT.loadAntibot([
        'PICTO',
        'AUDIO',
        'FR',
        process.env.REACT_APP_ANTIBOT_SP_KEY,
        process.env.REACT_APP_ANTIBOT_SP_SERVER,
        'captcha_display',
        null,
        antibotId,
        requestId,
        true
      ]);
      dispatch({type: ISNT_LOADING});
    } else {
      dispatch({type: ISNT_LOADING});
      dispatch({
        type: SET_ERROR,
        value: 'catpchaError'
      });
    }
  };
};
